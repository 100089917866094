import React, { useEffect, useState } from "react";
import "./Categories.css";
import { Button, Checkbox, useMediaQuery } from "@mui/material";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import {
  custServiceWishListAPI,
  custServiceListAPI,
} from "../../../../Config/apiConfig";
import axios from "axios";

const itemsPerPage = 5;
const Categories = ({ custSortSerData }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [wishListId, setWishListId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false); // New state variable
  const [custToken, setCustToken] = useState(null);
  const [isFavorite, setIsFavorite] = useState("");

  const isExtraSmallScreen = useMediaQuery("(max-width: 420px)");

  console.log("sort Service", custSortSerData);

  useEffect(() => {
    const response = localStorage.getItem("custLoginCredentials");
    const parsedData = response ? JSON.parse(response) : null;
    if (parsedData && parsedData.token) {
      setCustToken(parsedData.token);
    }
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const fetchCustServiceListAPI = async () => {
    try {
      const response = await axios.get(custServiceListAPI, {
        // headers: {
        //   "Content-Type": "application/json",
        //   Authorization: `Bearer ${custToken}`,
        // },
      });
      if (response && response.status === 200) {
        console.log("Service List", response.data);
        setIsFavorite(response.data.msg);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 500) {
        console.log(error);
      }
    }
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = custSortSerData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="categories_services__container w-100">
        <section className="product_card_section mt-4">
          {currentItems &&
            currentItems.map((item) => (
              <div
                key={item._id}
                className="services_img__container w-100 border mb-5 p-2 p-sm-4"
              >
                <div className="services__img-box result-content__box overflow-hidden border">
                  <img
                    src={item.service}
                    alt={item.title}
                    className="services__img"
                  />
                  <div className="overlay-text">
                    <Checkbox
                      icon={<FavoriteBorder />}
                      checkedIcon={<Favorite />}
                      // onChange={(event) =>
                      //   handleCheckboxChange(event, item._id)
                      // }
                      // onClick={(event) =>
                      //   handleCheckboxClick(event, item.childCategoryId)
                      // }
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-start justify-content-sm-between justify-content-md-start justify-content-lg-between align-items-start align-items-lg-center mt-1 mt-sm-3  ">
                  <div className="inline-block d-flex flex-column align-items-start w-100">
                    <div
                      className={`d-flex ${
                        isExtraSmallScreen
                          ? "flex-column align-items-start"
                          : "flex-row align-items-center"
                      }  gap-1 `}
                    >
                      <h2 className="categories--title gilroy text-lg-center m-0 p-0">
                        {item.title}
                      </h2>
                      <span className="checker gilroy ">
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={{
                            color: "#172b4d",
                            fontSize: "10px",
                          }}
                        />
                        &nbsp;
                        {item.status === "Approved"
                          ? "Certified"
                          : "Not Certified"}
                      </span>
                    </div>
                    <div
                      className={`company__address d-flex justify-content-center align-items-center justify-content-sm-start gap-1 gilroy`}
                    >
                      <p className="company__address m-0 p-0 gilroy">
                        {item.childCategoryName}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`d-flex ${
                      isExtraSmallScreen ? "flex-column" : "flex-row"
                    } 
                    flex-row flex-sm-column flex-md-row flex-lg-column ${
                      isExtraSmallScreen ? "gap-1" : "gap-3"
                    } gap-sm-0 gap-md-3 gap-lg-0 ${
                      isExtraSmallScreen
                        ? "align-items-start"
                        : "align-items-center"
                    } mt-1`}
                  >
                    <p className="categories__ratings text-center d-flex align-items-center m-0 p-0">
                      <span>
                        <FontAwesomeIcon
                          icon={faStar}
                          style={{
                            color: "#fa9b00",
                            fontSize: "18px",
                          }}
                        />
                      </span>
                      &nbsp;
                      <span className="ratings__outof fw-bold gilroy">
                        {item.ratingOutof}/5
                      </span>
                    </p>
                    <p className="categories__reviews m-0 p-0">
                      {item.reviews} reviews
                    </p>
                  </div>
                </div>
                <div className="mt-4">
                  <div
                    className={`d-flex ${
                      isExtraSmallScreen ? "flex-column" : "flex-row"
                    }  gap-2`}
                  >
                    <p className="service-giving m-0 ">Bed Bug termination</p>
                    <p className="service-giving m-0">Pest control</p>
                  </div>
                  <div className="d-flex align-items-start border mt-2">
                    <p className="service-para p-0 m-0 border">
                      {item.details}
                    </p>
                    {/* <button className="service-pare-expand">more</button> */}
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row flex-md-column flex-lg-row justify-content-between align-items-start align-items-sm-center align-items-md-start align-items-lg-center mt-4 gap-2">
                  <div
                    className={`d-flex ${
                      isExtraSmallScreen ? "flex-column" : "flex-row"
                    } gap-2 align-items-start align-items-lg-center w-100`}
                  >
                    <p className="categories__response text-center gilroy m-0">
                      Response: <span>Within an hour</span>
                    </p>
                    <p className="categories__open--time text-center gilroy m-0">
                      Open: <span>9 AM to 11 PM</span>
                    </p>
                  </div>
                  <div className="d-flex gap-2 w-sm-100 justify-content-start justify-content-sm-end justify-content-md-start justify-content-lg-end  align-items-center mt-3 mt-sm-0 mt-md-3 mt-lg-0">
                    <Button
                      sx={{
                        border: "1px solid rgba(194, 199, 207, 1)",
                        width: "36px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "4px",
                        padding: "0",
                        minWidth: "36px",
                      }}
                      className="share__btn "
                    >
                      <ShareRoundedIcon
                        fontSize="small"
                        style={{ color: "#6a7d9f" }}
                      />
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="gilroy"
                      sx={{ fontSize: "14px", width: "8rem" }}
                    >
                      Request Quote
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </section>
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(custSortSerData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
};

export default Categories;
