import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Home/Header/Navbar";
import ServiceProviderCatalogue from "./Components/Home/ServiceProviderCatalogue";
import Registration from "./Components/Registration/Registration";
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import Orders from "./Components/Orders/Orders";
import Auctions from "./Components/Auctions/Auctions";
import AuctionDetails from "./Components/Auctions/AuctionDetails/AuctionDetails";
import MyAccount from "./Components/SellerAccount/MyAccount";
import ChatsBoxContainer from "./Components/Chats/ChatsBoxContainer";
import ServicesProvided from "./Components/ServiceLists/ProvidedServices/ServicesProvided";
import ServiceCategories from "./Components/ServicesCategories/ServiceCategories";
import SetupProfile from "./Components/ProfileSetup/SetupProfile";
import SellerProfile from "./Components/SellerProfile/SellerProfile";
import Footer from "./Components/Footer/Footer";
import MyAuctions from "./Components/MyAuctions/MyAuctions";
import MyAuctionsDetails from "./Components/MyAuctions/MyAuctionsDetails/MyAuctionsDetails";
import BidderProfile from "./Components/MyAuctions/BidderProfile/BidderProfile";
import MyOrders from "./Components/MyOrders/MyOrders";
import OrderPage from "./Components/MyOrders/MyOrderPage/OrderPage";
import BuyersAccounts from "./Components/BuyersAccounts/BuyersAccounts";
import CustNotifications from "./Components/BuyersNotifications/CustNotifications";
import ProductsAndServices from "./Components/ProductsAndServices/ProductsAndServices";
import AddProducts from "./Components/ProductsAndServices/MyProducts/AddProducts/AddProducts";
import AddServices from "./Components/ProductsAndServices/MyServices/AddServices/AddServices";
import AddServiceDetails from "./Components/ProductsAndServices/MyServices/AddServiceDetails/AddServiceDetails";
import ProductDetails from "./Components/BuyersProAndSer/BuyerProductDetails/ProductDetails";
import ProductCart from "./Components/BuyersProAndSer/ProductCarts/ProductCart";
import BuyersProAndSer from "./Components/BuyersProAndSer/BuyersProAndSer";
import ServiceLists from "./Components/ServiceLists/ServiceLists";

const App = () => {
  const design = (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/" element={<Test />} /> */}
          {/* <Route path="*" element={<Notfound />} /> */}
          {/* Kritik */}

          <Route
            exact
            path="/service-provider-catalogue"
            element={<ServiceProviderCatalogue />}
          />
          <Route exact path="/dashboard" element={<Dashboard />} />

          {/* For Seller */}
          <Route exact path="/registration" element={<Registration />} />
          <Route exact path="/seller-profile" element={<SellerProfile />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/profile-setup" element={<SetupProfile />} />
          <Route
            exact
            path="/admin-products-services"
            element={<ProductsAndServices />}
          />
          <Route exact path="/add-products" element={<AddProducts />} />
          <Route exact path="/add-services" element={<AddServices />} />
          <Route
            exact
            path="/add-services-details"
            element={<AddServiceDetails />}
          />
          <Route exact path="/myaccount" element={<MyAccount />} />

          {/* For Buyers */}
          <Route exact path="/cust-accounts" element={<BuyersAccounts />} />
          <Route exact path="/services-lists" element={<ServiceLists />} />
          <Route
            exact
            path="/services-provided"
            element={<ServicesProvided />}
          />
          <Route
            exact
            path="/service-categories"
            element={<ServiceCategories />}
          />
          <Route exact path="/my-auctions" element={<MyAuctions />} />
          <Route
            exact
            path="/my-auction-details"
            element={<MyAuctionsDetails />}
          />
          <Route exact path="/service-provider" element={<BidderProfile />} />
          <Route exact path="/orders" element={<MyOrders />} />
          <Route exact path="/my-order-page" element={<OrderPage />} />
          <Route
            exact
            path="/cust-notifications"
            element={<CustNotifications />}
          />
          <Route
            exact
            path="/cust-product-service"
            element={<BuyersProAndSer />}
          />
          <Route
            exact
            path="/buyer-product-details"
            element={<ProductDetails />}
          />
          <Route exact path="/cust-product-cart" element={<ProductCart />} />

          <Route exact path="/my-orders" element={<Orders />} />
          <Route exact path="/auctions" element={<Auctions />} />
          <Route exact path="/auctionDetails" element={<AuctionDetails />} />

          <Route exact path="/chats" element={<ChatsBoxContainer />} />
        </Routes>
        {/* Kritik */}
        <Footer />
      </Router>
    </>
  );
  return design;
};

export default App;
